<template>
  <div>
    <nutrient-list-add-new
      :is-add-new-nutrient-sidebar-active.sync="isAddNewNutrientSidebarActive"
      @refetch-data="fetchNutrients"
    />
    <nutrient-list-edit
      :key="selectedNutrient.id"
      :is-edit-nutrient-sidebar-active.sync="isEditNutrientSidebarActive"
      :nutrient.sync="selectedNutrient"
      @refetch-data="fetchNutrients"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("eachPage") }}</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $t("piece") }} </label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="
                  isAddNewNutrientSidebarActive = !isAddNewNutrientSidebarActive
                "
              >
                <span class="text-nowrap">{{ $t("nutrientAdd") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refNutrientListTable"
        class="position-relative"
        :items="filteredNutrients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!-- Column: Nutrient -->
        <template #cell(nutrientname)="data">
          <b-link @click="openEditSideBar(data)">
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.nutrientname }}
            </span>
          </b-link>
        </template>
        <template #cell(tipname)="data">
          {{ data.item.tipname == "Gerçek" ? $t("real") : $t("rate") }}
        </template>
        <template #cell(delete)="data">
          <b-link
            @click="
              deleteNutrient({
                plantid: $route.params.id,
                nutrientid: data.item.id,
              })
            "
          >
            <feather-icon icon="TrashIcon" />
            <small class="align-middle ml-50 text-dark">{{
              $t("delete")
            }}</small>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <feather-icon icon="EditIcon" />
            <small class="align-middle ml-50 text-dark">{{ $t("edit") }}</small>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              ></span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filteredNutrients.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useNutrientList from "./useNutrientList";
import NutrientListAddNew from "./NutrientListAddNew.vue";
import NutrientListEdit from "./NutrientListEdit.vue";

import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";

export default {
  components: {
    NutrientListAddNew,
    NutrientListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    vSelect,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.name == "nutrients") {
      to.meta.breadcrumb = [];

      to.meta.breadcrumb.push({
        text: VueI18n.t("nutrients"),
        active: true,
      });
    }
    next();
  },
  setup({ emit }) {
    const isAddNewNutrientSidebarActive = ref(false);
    const isEditNutrientSidebarActive = ref(false);
    const selectedNutrient = ref({});
    const nutrientData = getUserData();
    const openEditSideBar = (item) => {
      selectedNutrient.value = item;
      isEditNutrientSidebarActive.value = !isEditNutrientSidebarActive.value;
    };
    const {
      fetchNutrients,
      tableColumns,
      perPage,
      currentPage,
      totalNutrients,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refNutrientListTable,
      refetchData,
      filteredNutrients,
      allNutrients,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteNutrient,
    } = useNutrientList();
    fetchNutrients();
    return {
      // Sidebar
      isAddNewNutrientSidebarActive,
      isEditNutrientSidebarActive,
      openEditSideBar,
      filteredNutrients,
      allNutrients,

      selectedNutrient,

      fetchNutrients,
      tableColumns,
      perPage,
      currentPage,
      totalNutrients,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refNutrientListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteNutrient,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
