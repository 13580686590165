<template>
  <b-sidebar
    id="add-new-nutrient-sidebar"
    centered
    :visible="isAddNewNutrientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="lg"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    @ok="$refs.submitButtonRef.click()"
    @hidden="formValidation(resetblanknutrient).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("nutrientAdd") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblanknutrient
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group label="Kod" label-for="kod">
              <b-form-input
                id="kod"
                v-model="blankNutrientData.kod"
                autofocus
                :state="
                  formValidation(resetblanknutrient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group :label="$t('name')" label-for="name">
              <b-form-input
                id="name"
                v-model="blankNutrientData.nutrientname"
                autofocus
                :state="
                  formValidation(resetblanknutrient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group :label="$t('type')" label-for="type">
            <v-select
              v-model="blankNutrientData.tip"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tip"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => changeTip(val)"
            />
          </b-form-group>
          <b-form-group :label="$t('unit')" label-for="Birim">
            <v-select
              v-model="blankNutrientData.birim"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="birim"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <b-form-group v-if="!oran" label="BMI1" label-for="bmid1">
            <v-select
              v-model="blankNutrientData.bmid1"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="products"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <b-form-group v-if="!oran" label="BMI2" label-for="bmid2">
            <v-select
              v-model="blankNutrientData.bmid2"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="products"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group>
          <b-card-actions
            v-if="oran"
            :title="$t('values')"
            action-collapse
            :collapsed="true"
          >
            <!-- Values -->
            <div
              v-for="value in blankNutrientData.values"
              :key="value.id"
              rules="required"
              name="fiyat"
            >
              <b-form-group :label="value.ingredientname" label-for="fiyat">
                <BInputGroup>
                  <b-form-input
                    :id="value.ingredientname"
                    v-model="value.deger"
                    step="any"
                    type="number"
                    trim
                    placeholder=""
                  />
                  <b-input-group-append is-text>
                    <span>{{ value.birim }}</span>
                  </b-input-group-append>
                </BInputGroup>
              </b-form-group>
            </div>
          </b-card-actions>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardActions,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewNutrientSidebarActive",
    event: "update:is-add-new-nutrient-sidebar-active",
  },
  props: {
    isAddNewNutrientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankNutrientData: {},
      oran: true,
      tip: [
        {
          label: this.$t("real"),
          value: 1,
        },
        {
          label: this.$t("rate"),
          value: 2,
        },
      ],
      birim: [
        { label: "%", value: 1 },
        { label: "MCal/Kg", value: 2 },
        { label: "Gram/Kg", value: 3 },
      ],
      formValidation: formValidation,
      products: [],
    };
  },
  async mounted() {
    await this.fetchProducts();
    this.changeTip();
  },
  methods: {
    changeTip(value) {
      if (value) this.oran = value == 1;
    },
    async fetchNewNutrient() {
      var { data } = await this.$http.get("/Nutrients/GetNewNutrient/", {
        headers: { culture: getUserData().cultureinfo, tip: "0", plantid: "0" },
      });
      this.blankNutrientData = data.resultdata[0];
      this.blankNutrientData.tip = 1;
    },
    async fetchProducts() {
      var products = await store.dispatch(
        "companyProductsModule/fetchCompanyProducts"
      );
      this.products = products.map((x) => {
        return {
          label: x.ingredientname,
          value: x.id,
        };
      });
    },
    async changed(val) {
      if (val == true) await this.fetchNewNutrient();
      this.$emit("update:is-add-new-nutrient-sidebar-active", val);
    },
    onSubmit() {
      var tinyNutrient = {
        values: [],
      };
      tinyNutrient.id = this.blankNutrientData.id;
      tinyNutrient.tip = this.blankNutrientData.tip;
      tinyNutrient.nutrientname = this.blankNutrientData.nutrientname;
      tinyNutrient.kod = this.blankNutrientData.kod;
      tinyNutrient.birim = this.blankNutrientData.birim;
      if (!this.oran) {
        tinyNutrient.bmid1 = this.blankNutrientData.bmid1;
        tinyNutrient.bmid2 = this.blankNutrientData.bmid2;
      }
      this.blankNutrientData.values.map((x) => {
        if (x.deger != 0) {
          tinyNutrient.values.push({
            id: x.id,
            deger: parseFloat(x.deger),
          });
        }
      });
      store.dispatch("plantsModule/addNutrient", tinyNutrient).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-add-new-nutrient-sidebar-active", false);
      });
    },
    async resetblanknutrient() {
      this.blankNutrientData = await this.fetchNewNutrient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-nutrient-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
